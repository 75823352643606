.swal2-container {
  z-index: 1500 !important;
}

.jodit-workplace li {
  margin-left: 2rem;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.simplebar-content a.active:hover {
  background-color: #e20246;
}
/* input { padding: 10px 0px !important;}  */
/* input:hover { border-color: currentColor !important;} */
/* .simplebar-content table thead th:nth-child(3) { width: 500px;} */
/* .simplebar-content table tbody td:nth-child(3) { width: 0px;} */

.jodit-react-container,
.jodit-container {
  width: 100%;
}

iframe {
  display: none;
}

.one-line {
  white-space: nowrap;
  overflow: hidden;
  width: 70%;
  text-overflow: ellipsis;
}

.one-line-w-90 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90%;
}

.ql-snow .ql-tooltip {
  z-index: 100;
}

.ql-toolbar {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.quill-editor .ql-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ql-editor {
  min-height: 7rem;
}
.MuiLoadingButton-root{
  border: 1px solid #E20246;
}
.MuiButton-text{
  background-color: white;
  border: 1px solid #E20246;
  color: #E20246;
}

.profile-icon {
  background-color: #e20246 !important;
  width: 250px;
  justify-content: space-between;
  border-radius: 50px !important;
  padding: 5px 10px;
}
.profile-icon button {
  display: inline-flex;
  justify-content: left;
  width: 100%;
  /* z-index: 1000; */
}
.profile-icon button img {
  position: absolute;
  /* right: 10px; */
  /* top: 0px; */
}
.drop-arow {right: 20px !important;}

.profile-icon p {
  margin: 0px;
  font-size: 15px;
  font-weight: 600;
  color: #ffffff !important;
  padding-left: 10px;
}

/* .login-bg { background-image: ; } */
.form-login { min-height: 100vh; display: flex; flex-direction: column; justify-content: center;}
.bg-login-img img { position: absolute; bottom: 0px;}
.MuiDrawer-paper { border: 0px !important;}
/* .MuiTextField { padding: 10px !important;} */
.save-btn{
  background-color: #E20246;
  color:#ffffff
}
.save-btn:hover {
  background-color: #E20246;
  color:#ffffff
}
/* .manage-order-box { background-color: #ffffff; padding: 20px;margin-bottom: 30px;}
.personalize-wrapper-sub .image-wrapper { height: 130px;}
.personalize-wrapper-sub .image-wrapper img { height: 100%; width: 100%; object-fit: contain;}
.manage-order-context h5 { color: #cf103e; font-size: 22px; font-weight: 500;}
.manage-order-context li { color: #cf103e; font-size: 22px; font-weight: 500;} */

/* .manage-order-box {display: flex; min-height: 254px;} */
/* .manage-order-box-sub {display: inline-flex; justify-content: start; align-items: center;} */

/* .card-media-img {width: 30%;} */
.card-media-img img { height: 150px; width: 150px; object-fit: contain; mix-blend-mode: multiply; }
/* .inline-card-content li { list-style: none;} */
/* .inline-card-content { list-style: decimal;} */
/* .inline-card-content { list-style: disc !important;} */
.card-acc-headtext {font-size: 1.25rem; font-weight: 700;}
/* .picknmix-text { font-size: 22px; position: absolute; top: 20px; left: 0px; right: 0px; display: block;} */
.picknmix-text { font-size: 22px; text-align: center; padding-top: 10px; padding-bottom: 20px; text-decoration: underline;}
.new-acc { background-color: transparent !important;}
.new-acc .card-content { padding: 0px;}
.card-content { width: 100%;}
/* ========================================= */
.align-item-pick {display: flex;}
.card-picknmix { background: #ffffff; transition: 0.3s; border-radius: 15px; padding: 20px 20px 50px 20px; border: 1px solid #f1f1f1;   box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);}
.card-picknmix:hover { background: #f1f1f1}
.w-30{ width: 20%; justify-content: center; text-align: center;}
.w-70 { width: 80%;}
.align-item-pick-sub { align-items: center;}
.card-picknmix-2 { padding: 20px;}



